// src/common/components/Select/common/components/Profile/index.tsx
import Select, { VariantType } from "common/components/Select";
import Input from "common/components/Input";
import { Profile } from "features/profile/types";
import { useGetProfileListQuery } from "features/profile/api";
import { useTranslation } from "react-i18next";

interface SelectProfileProps {
  perfil_ativo?: boolean;
  onChange?: (profile: Profile | null) => void;
  value?: string | number | Profile;
  className?: string;
  variant?: VariantType;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  empresa_id?: number;
  mode?: "view" | "edit" | "create";
}

const SelectProfile = ({
  perfil_ativo = true,
  onChange,
  value: externalValue,
  className,
  variant,
  label,
  required,
  disabled,
  empresa_id,
  mode = "create",
}: SelectProfileProps) => {
  const { t } = useTranslation();
  const { data, isLoading, error, isFetching } = useGetProfileListQuery(
    {
      perfil_ativo,
      empresa_id,
    },
    { refetchOnMountOrArgChange: true },
  );

  const normalizeValue = (
    value: string | number | Profile | undefined,
  ): string => {
    if (!value) return "";
    if (typeof value === "object" && "perfil_id" in value) {
      return String(value.perfil_id);
    }
    return String(value);
  };

  const selectValue = normalizeValue(externalValue);

  const options =
    data?.data?.result?.map((profile) => ({
      value: profile.perfil_id.toString(),
      label: profile.nome_perfil,
    })) ?? [];

  const handleChange = (selectedValue: string) => {
    const selectedProfile =
      data?.data?.result?.find(
        (profile) => profile.perfil_id.toString() === selectedValue,
      ) ?? null;

    if (onChange && selectedProfile) {
      onChange(selectedProfile);
    }
  };

  const getDisplayValue = () => {
    if (!externalValue) return "";
    if (typeof externalValue === "object" && "nome_perfil" in externalValue) {
      return externalValue.nome_perfil;
    }
    const profile = data?.data?.result?.find(
      (p) => p.perfil_id.toString() === String(externalValue),
    );
    return profile?.nome_perfil || String(externalValue);
  };

  if (mode === "view") {
    return (
      <Input
        value={getDisplayValue()}
        label={label ?? ""}
        disabled={true}
        className={className}
      />
    );
  }

  return (
    <Select
      variant={variant}
      options={options}
      value={selectValue}
      onChange={handleChange}
      className={className}
      isLoading={isLoading || isFetching}
      disabled={disabled || isLoading || isFetching || options.length === 0}
      placeholder={isLoading ? t("profile.loading") : t("profile.placeholder")}
      error={error ? t("profile.error") : undefined}
      label={label ?? ""}
      required={required}
      searchable={true}
    />
  );
};

export default SelectProfile;
