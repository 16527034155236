import { useTranslation } from "react-i18next";
import ErrorAlert from "common/components/ErrorAlert";
import Spinner from "common/components/Spinner";
import RolesContainer from "features/project/components/PDIProject/components/Project/components/Stepper/steps/Roles/components/RolesContainer";
import RoleColumn from "features/project/components/PDIProject/components/Project/components/Stepper/steps/Roles/components/RoleColumn";
import RolesView from "features/project/components/PDIProject/components/Project/components/Stepper/steps/Roles/components/RolesView";
import { useRoles } from "features/project/components/PDIProject/components/Project/components/Stepper/steps/Roles/hooks/useRoles";
import ConfirmModal from "common/components/Modal/common/components/ConfirmModal";
import SuccessModal from "common/components/Modal/common/components/SuccessModal";
import InviteUserModal from "features/project/components/PDIProject/components/Project/components/Stepper/steps/Roles/components/InviteUserModal";
import AddUserModal from "features/project/components/PDIProject/components/Project/components/Stepper/steps/Roles/components/AddUserModal";

interface RoleUser {
  id: number;
  name: string;
  status?: "pending" | "active";
  projeto_usuario_papel_id?: number;
}

interface RoleColumnData {
  id: number;
  title: string;
  users: RoleUser[];
  allowsInvite: boolean;
}

interface RolesProps {
  projectId?: number;
  viewMode?: "view" | "edit" | "create";
}

const Roles = ({ projectId, viewMode = "edit" }: RolesProps) => {
  const { t } = useTranslation();
  const isViewMode = viewMode === "view";

  const {
    roleColumns,
    loading,
    error,
    setError,
    showRemoveConfirmation,
    handleRemoveUser,
    isConfirmModalOpen,
    isSuccessModalOpen,
    userToRemove,
    isRemoving,
    closeSuccessModal,
    cancelRemoval,
    handleRoleHeaderClick,
    isInviteModalOpen,
    setIsInviteModalOpen,
    isAddUserModalOpen,
    setIsAddUserModalOpen,
    selectedRole,
    handleInviteUser,
    handleAddUser,
    isSubmitting,
    projectName,
    inviteError,
    setInviteError,
    addUserError,
    setAddUserError,
  } = useRoles({
    projectId,
    viewMode,
  });

  return (
    <RolesContainer>
      <div className="flex flex-col gap-4 h-full">
        {error && (
          <ErrorAlert
            message={error}
            onDismiss={() => setError(null)}
            className="mb-2"
          />
        )}

        {loading && (
          <div className="flex justify-center items-center py-8">
            <Spinner
              size="lg"
              label={t("common.loading")}
              className="flex flex-col gap-4 items-center justify-center"
            />
          </div>
        )}

        {!loading && isViewMode && <RolesView roleColumns={roleColumns} />}

        {!loading && !isViewMode && (
          <div className="flex flex-col gap-4 overflow-y-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5">
              {roleColumns.map((column: RoleColumnData) => (
                <RoleColumn
                  key={column.id}
                  title={column.title}
                  users={column.users}
                  allowsInvite={column.allowsInvite}
                  isViewMode={isViewMode}
                  onRemoveUser={showRemoveConfirmation}
                  onHeaderClick={handleRoleHeaderClick}
                  roleId={column.id}
                />
              ))}
            </div>
          </div>
        )}

        <ConfirmModal
          isOpen={isConfirmModalOpen}
          onClose={cancelRemoval}
          onConfirm={handleRemoveUser}
          title={t("project.rolesStep.modal.removeUser.confirmation.title")}
          message={
            userToRemove
              ? t("project.rolesStep.modal.removeUser.confirmation.message", {
                  name: userToRemove.name,
                })
              : ""
          }
          confirmButtonText={t(
            "project.rolesStep.modal.removeUser.confirmation.confirmButtonText",
          )}
          cancelButtonText={t(
            "project.rolesStep.modal.removeUser.confirmation.cancelButtonText",
          )}
          isLoading={isRemoving}
        />

        <SuccessModal
          isOpen={isSuccessModalOpen}
          onClose={closeSuccessModal}
          title={t("project.rolesStep.modal.success.title")}
          message={
            userToRemove
              ? t("project.rolesStep.modal.success.message", {
                  name: userToRemove.name,
                })
              : ""
          }
          confirmButtonText={t("button.close")}
        />

        <InviteUserModal
          isOpen={isInviteModalOpen}
          onClose={() => setIsInviteModalOpen(false)}
          onInvite={handleInviteUser}
          projectName={projectName || t("project.label")}
          roleName={selectedRole?.title || ""}
          isSubmitting={isSubmitting}
          error={inviteError}
          setError={setInviteError}
        />

        <AddUserModal
          isOpen={isAddUserModalOpen}
          onClose={() => setIsAddUserModalOpen(false)}
          onAdd={handleAddUser}
          isSubmitting={isSubmitting}
          error={addUserError}
          setError={setAddUserError}
        />
      </div>
    </RolesContainer>
  );
};

export default Roles;
