import { useTranslation } from "react-i18next";
// import Input from "common/components/Input";
import { IdentificationFormState } from "features/project/slices/projectFormSlice";
import Select from "common/components/Select";
import { useGetProgramsQuery } from "features/project/api";

interface ThemeInformationProps {
  formData: IdentificationFormState;
  handleInputChange: (
    field: keyof IdentificationFormState,
    value: string | number | Date | null,
  ) => void;
  isViewMode: boolean;
}

const ThemeInformation = ({
  formData,
  handleInputChange,
  isViewMode,
}: ThemeInformationProps) => {
  const { t } = useTranslation();
  const { data: programsData, isLoading: isLoadingPrograms } =
    useGetProgramsQuery();

  return (
    <div className="flex flex-col gap-3 p-2 rounded-md border border-[--table-header-bg] bg-[--breadcrumb-bg] shadow-sm">
      <h4 className="text-sm text-primary-dark font-semibold p-1.5 bg-[--primary-light] rounded-md shadow-sm text-center">
        {t("project.identificationStep.sections.themeInformation")}
      </h4>

      <div className="flex flex-col gap-4 p-1">
        <div className="grid grid-cols-1 gap-3">
          <Select
            label={t("project.identificationStep.fields.program") || "Programa"}
            value={formData.programa_id || ""}
            onChange={(value) => handleInputChange("programa_id", value)}
            disabled={isViewMode || isLoadingPrograms}
            className={`${isViewMode ? "bg-gray-100" : ""} border-[--table-header-bg]`}
            options={
              programsData?.data?.map((program) => ({
                value: program.programa_id.toString(),
                label: program.descricao,
              })) || []
            }
          />
        </div>
        {/* <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
          <Input
            label={t("project.identificationStep.fields.themeCode")}
            value={formData.codigo_tema}
            onChange={(e) => handleInputChange("codigo_tema", e.target.value)}
            readOnly={isViewMode}
            disabled={isViewMode}
            className={`${isViewMode ? "bg-gray-100" : ""} border-[--table-header-bg]`}
          />
          <Input
            label={t("project.identificationStep.fields.otherTheme")}
            value={formData.outro_tema}
            onChange={(e) => handleInputChange("outro_tema", e.target.value)}
            readOnly={isViewMode}
            disabled={isViewMode}
            className={`${isViewMode ? "bg-gray-100" : ""} border-[--table-header-bg]`}
          />
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
          <Input
            label={t("project.identificationStep.fields.subthemeCode")}
            value={formData.codigo_subtema}
            onChange={(e) =>
              handleInputChange("codigo_subtema", e.target.value)
            }
            readOnly={isViewMode}
            disabled={isViewMode}
            className={`${isViewMode ? "bg-gray-100" : ""} border-[--table-header-bg]`}
          />
          <Input
            label={t("project.identificationStep.fields.otherSubtheme")}
            value={formData.outro_subtema}
            onChange={(e) => handleInputChange("outro_subtema", e.target.value)}
            readOnly={isViewMode}
            disabled={isViewMode}
            className={`${isViewMode ? "bg-gray-100" : ""} border-[--table-header-bg]`}
          />
        </div> */}
      </div>
    </div>
  );
};

export default ThemeInformation;
