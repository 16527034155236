import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Modal from "common/components/Modal";
import Button from "common/components/Button";
import Select from "common/components/Select";
import { RootState } from "store";
import { useGetCompanyUsersQuery } from "features/company/api";
import ErrorAlert from "common/components/ErrorAlert";
interface UserOption {
  id: number;
  name: string;
}

interface AddUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAdd: (userId: number) => void;
  isSubmitting: boolean;
  error: string | null;
  setError: (error: string | null) => void;
}

const AddUserModal = ({
  isOpen,
  onClose,
  onAdd,
  isSubmitting,
  error,
  setError,
}: AddUserModalProps) => {
  const { t } = useTranslation();
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [users, setUsers] = useState<UserOption[]>([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);

  const companyId = useSelector(
    (state: RootState) => state.auth.user?.empresa_logada?.empresa_id,
  );

  const { data: companyUsers, isLoading } = useGetCompanyUsersQuery(
    { id: companyId || 0, params: { page: 1, perPage: 100 } },
    { skip: !companyId, refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    setIsLoadingUsers(isLoading);
    if (companyUsers?.data?.result) {
      const mappedUsers = companyUsers.data.result.map((user) => ({
        id: user.usuario_id,
        name: user.nome,
      }));
      setUsers(mappedUsers);
    }
  }, [companyUsers, isLoading]);

  const handleSubmit = () => {
    onAdd(selectedUserId || 0);
    setSelectedUserId(null);
    setError(null);
  };

  const handleClose = () => {
    setSelectedUserId(null);
    setError(null);
    onClose();
  };

  const userOptions = users.map((user) => ({
    value: user.id.toString(),
    label: user.name,
  }));

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title="Adicionar Usuário"
      size="xl"
      closeOnBackdropClick={false}
      className="flex flex-col w-[1000px] max-w-[95vw] h-[500px] max-h-[90vh]"
      isLoading={isLoadingUsers}
      footer={
        <div className="flex justify-end gap-4">
          <Button
            variant="link"
            onClick={handleClose}
            disabled={isSubmitting || isLoadingUsers}
          >
            {t("button.cancel")}
          </Button>
          <Button
            onClick={handleSubmit}
            loading={isSubmitting}
            disabled={isLoadingUsers}
          >
            {t("button.add")}
          </Button>
        </div>
      }
    >
      <div className="p-4">
        {error && (
          <ErrorAlert
            message={error}
            onDismiss={() => setError(null)}
            className="mb-2"
          />
        )}

        <div className="mb-4">
          <Select
            label="Usuário"
            value={selectedUserId ? selectedUserId.toString() : ""}
            onChange={(value) =>
              setSelectedUserId(value ? parseInt(value) : null)
            }
            options={userOptions}
            placeholder="Selecione um usuário"
            disabled={isSubmitting || isLoadingUsers}
            isLoading={isLoadingUsers}
            required
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddUserModal;
