// src/common/components/Input/common/components/UnifiedLocationForm/index.tsx
import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useGetUfQuery, useGetMunicipalityQuery } from "features/location/api";
import { CrudComponentProps, CrudMode } from "features/crud/types";
import Input from "common/components/Input";
import SelectUF from "common/components/Select/common/components/Uf";
import SelectMunicipality from "common/components/Select/common/components/Municipality";
import Phone from "common/components/Input/common/components/Phone";
import { FetchCepData } from "./api";
import { formatPhone } from "common/utils/formatters";

export interface LocationFormData {
  cep: string;
  endereco: string;
  numero: string;
  bairro: string;
  municipio_ibge_id: number;
  uf_id: number;
  telefone: string;
}

const defaultValue: LocationFormData = {
  cep: "",
  endereco: "",
  numero: "",
  bairro: "",
  municipio_ibge_id: 0,
  uf_id: 0,
  telefone: "",
};

interface UnifiedLocationFieldProps
  extends CrudComponentProps<LocationFormData> {
  onFieldChange?: (field: keyof LocationFormData, value: any) => void;
  mode?: CrudMode;
}

const UnifiedLocationField = ({
  value = defaultValue,
  onChange,
  onFieldChange,
  error,
  disabled,
  mode = "create",
}: UnifiedLocationFieldProps) => {
  const { t } = useTranslation();
  const [selectedUf, setSelectedUf] = useState<string | undefined>(
    value?.uf_id ? value.uf_id.toString() : undefined,
  );
  const [internalCep, setInternalCep] = useState(value?.cep || "");
  const [formData, setFormData] = useState<LocationFormData>(
    value || defaultValue,
  );
  const { data: ufData } = useGetUfQuery();
  const { data: municipalityData } = useGetMunicipalityQuery(selectedUf ?? "", {
    skip: !selectedUf,
  });

  useEffect(() => {
    if (value) {
      setFormData(value);
      if (value.uf_id) {
        setSelectedUf(value.uf_id.toString());
      }
      if (value.cep) {
        setInternalCep(value.cep);
      }
    }
  }, [value]);

  const formatCep = useCallback((cep: string) => {
    const cleaned = cep.replace(/\D/g, "");
    return cleaned.length <= 8
      ? cleaned.replace(/(\d{5})(\d{0,3})/, "$1-$2").trim()
      : cleaned.replace(/(\d{5})(\d{3}).*/, "$1-$2");
  }, []);

  useEffect(() => {
    if (value?.cep) {
      const formattedCep = formatCep(value.cep);
      setInternalCep(formattedCep);
    }
  }, [value?.cep, formatCep]);

  const handleFieldChange = useCallback(
    (field: keyof LocationFormData, fieldValue: any) => {
      const newValue: LocationFormData = {
        ...formData,
        [field]: fieldValue,
      };

      setFormData(newValue);
      onChange?.(newValue as LocationFormData);
      onFieldChange?.(field, fieldValue);
    },
    [formData, onChange, onFieldChange],
  );

  const handleCepChange = useCallback(
    async (cepInput: string) => {
      const maskedCep = formatCep(cepInput);
      setInternalCep(maskedCep);
      handleFieldChange("cep", maskedCep);

      const cleanCep = maskedCep.replace(/\D/g, "");

      if (cleanCep.length === 8) {
        try {
          const fetchedData = await FetchCepData(cleanCep);

          if (fetchedData) {
            const updatedData: Partial<LocationFormData> = {
              endereco: fetchedData.logradouro,
              bairro: fetchedData.bairro,
              cep: maskedCep,
            };

            if (fetchedData.uf && ufData?.data) {
              const matchingUf = ufData.data.find(
                (uf) => uf.sigla_uf === fetchedData.uf,
              );

              if (matchingUf) {
                updatedData.uf_id = matchingUf.uf_id;
                updatedData.municipio_ibge_id = fetchedData.ibge
                  ? parseInt(fetchedData.ibge)
                  : 0;
                setSelectedUf(matchingUf.uf_id.toString());
              }
            }

            const newValue: LocationFormData = {
              ...formData,
              ...updatedData,
            };

            setFormData(newValue);
            onChange?.(newValue as LocationFormData);

            Object.entries(updatedData).forEach(([field, value]) => {
              onFieldChange?.(field as keyof LocationFormData, value);
            });
          }
        } catch (error) {
          console.error("Error fetching CEP:", error);
        }
      }
    },
    [ufData, formatCep, handleFieldChange, formData, onChange, onFieldChange],
  );

  const handleUfChange = useCallback(
    (uf: any) => {
      if (uf) {
        setSelectedUf(uf.uf_id.toString());
        handleFieldChange("uf_id", uf.uf_id);
        handleFieldChange("municipio_ibge_id", 0);
      }
    },
    [handleFieldChange],
  );

  const handleMunicipalityChange = useCallback(
    (municipality: any) => {
      if (municipality) {
        handleFieldChange("municipio_ibge_id", municipality.municipio_id);
      }
    },
    [handleFieldChange],
  );

  const getUfLabel = useCallback(() => {
    if (!selectedUf || !ufData?.data) return "";
    const uf = ufData.data.find((u) => u.uf_id.toString() === selectedUf);
    return uf?.sigla_uf || "";
  }, [selectedUf, ufData]);

  const getMunicipalityLabel = useCallback(() => {
    if (!formData.municipio_ibge_id || !municipalityData?.data) return "";
    const municipality = municipalityData.data.find(
      (m) => m.municipio_id === formData.municipio_ibge_id,
    );
    return municipality?.nome_municipio || "";
  }, [formData.municipio_ibge_id, municipalityData]);

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-2">
      <div className="col-span-1">
        {mode === "view" ? (
          <Input
            label={t("cep.label")}
            value={internalCep}
            disabled={true}
            required
          />
        ) : (
          <Input
            label={t("cep.label")}
            value={internalCep}
            onChange={(e) => handleCepChange(e.target.value)}
            placeholder={t("cep.placeholder")}
            disabled={disabled}
            required
          />
        )}
      </div>

      <div className="md:col-span-2 lg:col-span-2">
        <Input
          label={t("address.label")}
          value={formData.endereco}
          onChange={(e) => handleFieldChange("endereco", e.target.value)}
          disabled={disabled || mode === "view"}
          required
        />
      </div>

      <div className="col-span-1">
        <Input
          label={t("address.number")}
          value={formData.numero}
          onChange={(e) => handleFieldChange("numero", e.target.value)}
          disabled={disabled || mode === "view"}
          required
        />
      </div>

      <div className="col-span-1">
        <Input
          label={t("address.neighborhood")}
          value={formData.bairro}
          onChange={(e) => handleFieldChange("bairro", e.target.value)}
          disabled={disabled || mode === "view"}
          required
        />
      </div>

      <div className="col-span-1">
        {mode === "view" ? (
          <Input
            label={t("uf.label")}
            value={getUfLabel()}
            disabled={true}
            required
          />
        ) : (
          <SelectUF
            value={selectedUf}
            onChange={handleUfChange}
            disabled={disabled}
            required
          />
        )}
      </div>

      <div className="col-span-1">
        {mode === "view" ? (
          <Input
            label={t("municipality.label")}
            value={getMunicipalityLabel()}
            disabled={true}
            required
          />
        ) : (
          <SelectMunicipality
            ufId={selectedUf ? parseInt(selectedUf) : undefined}
            value={formData.municipio_ibge_id?.toString() || ""}
            onChange={handleMunicipalityChange}
            disabled={disabled || !selectedUf}
            required
          />
        )}
      </div>

      <div className="md:col-span-2 lg:col-span-1">
        {mode === "view" ? (
          <Input
            label={t("address.phone")}
            value={formData.telefone ? formatPhone(formData.telefone) : ""}
            disabled={true}
            required
          />
        ) : (
          <Phone
            value={formData.telefone}
            onChange={(val) => handleFieldChange("telefone", val)}
            label={t("address.phone")}
            error={error}
            className="w-full"
            disabled={disabled}
          />
        )}
      </div>
    </div>
  );
};

export default UnifiedLocationField;
