import { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "common/components/Modal";
import Button from "common/components/Button";
import Input from "common/components/Input";
import ErrorAlert from "common/components/ErrorAlert";

interface InviteUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  onInvite: (data: { name: string; email: string }) => void;
  projectName: string;
  roleName: string;
  isSubmitting: boolean;
  error: string | null;
  setError: (error: string | null) => void;
}

const InviteUserModal = ({
  isOpen,
  onClose,
  onInvite,
  projectName,
  roleName,
  isSubmitting,
  error,
  setError,
}: InviteUserModalProps) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = () => {
    onInvite({ name, email });
    setName("");
    setEmail("");
    setError(null);
  };

  const handleClose = () => {
    setName("");
    setEmail("");
    setError(null);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title="Convidar Usuário"
      closeOnBackdropClick={false}
      size="xl"
      footer={
        <div className="flex justify-end gap-4">
          <Button variant="link" onClick={handleClose} disabled={isSubmitting}>
            {t("button.cancel")}
          </Button>
          <Button onClick={handleSubmit} loading={isSubmitting}>
            {t("button.send")}
          </Button>
        </div>
      }
    >
      <div className="p-4">
        {error && (
          <ErrorAlert
            message={error}
            onDismiss={() => setError(null)}
            className="mb-2"
          />
        )}

        <div className="grid sm:grid-cols-2 grid-cols-1 gap-4 mb-4">
          <Input
            label={t("input.name.label")}
            value={name}
            onChange={(e) => setName(e.target.value)}
            disabled={isSubmitting}
            required
          />
          <Input
            label={t("email.label")}
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={isSubmitting}
            required
          />
        </div>

        <div className="grid sm:grid-cols-2 grid-cols-1 gap-4 mb-4">
          <Input label="Projeto" value={projectName} readOnly />
          <Input label="Papel" value={roleName} readOnly />
        </div>
      </div>
    </Modal>
  );
};

export default InviteUserModal;
