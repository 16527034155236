// src/features/crud/utils/layout.ts
export type ColumnSize = 1 | 2 | 3 | 4 | 6 | 8 | 9 | 10 | 11 | 12;
export type Breakpoint = "sm" | "md" | "lg" | "xl" | "2xl";

export interface FieldLayout {
  colSpan?: {
    [key in Breakpoint]?: ColumnSize;
  };
  rowSpan?: number;
  order?: number;
}

export interface LayoutGroup {
  title?: string;
  fields: string[];
  columns?: {
    [key in Breakpoint]?: number;
  };
  fieldLayouts?: {
    [key: string]: FieldLayout;
  };
}

export interface CrudLayout {
  groups: LayoutGroup[];
  fieldLayouts?: {
    [key: string]: FieldLayout;
  };
}

export const generateGridClasses = (columns: {
  [key in Breakpoint]?: number;
}): string => {
  const baseClass = "grid gap-2";

  const breakpointClasses = Object.entries(columns)
    .map(([breakpoint, cols]) =>
      breakpoint === "sm"
        ? `grid-cols-${cols}`
        : `${breakpoint}:grid-cols-${cols}`,
    )
    .join(" ");

  return `${baseClass} ${breakpointClasses}`;
};

export const generateFieldSpanClasses = (layout?: FieldLayout): string => {
  if (!layout?.colSpan) return "";

  return Object.entries(layout.colSpan)
    .map(([breakpoint, span]) =>
      breakpoint === "sm"
        ? `col-span-${span}`
        : `${breakpoint}:col-span-${span}`,
    )
    .join(" ");
};
