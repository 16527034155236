// src/features/people/api/index.ts

import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "config/baseQuery";
import {
  GetPeopleParams,
  GetPeopleResponse,
  GetPersonByIdResponse,
  SavePersonRequest,
  SavePersonResponse,
} from "features/people/types";

export const peopleApi = createApi({
  reducerPath: "peopleApi",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["People", "AcademicDegrees"],
  endpoints: (builder) => ({
    // Listar pessoas {{url_api}}/pessoa?filtro_generico=marlon&page=1&perPage=50&sortDirection=desc&sortBy=nome
    getPeople: builder.query<GetPeopleResponse, GetPeopleParams>({
      query: (params) => ({
        url: "/pessoa",
        params,
      }),
      providesTags: ["People"],
      keepUnusedDataFor: 0,
    }),

    // getPessoaById {{url_api}}/pessoa/8
    getPersonById: builder.query<GetPersonByIdResponse, number>({
      query: (id) => ({
        url: `/pessoa/${id}`,
      }),
      providesTags: (_result, _error, id) => [{ type: "People", id }],
    }),

    // Inserir Pessoa {{url_api}}/pessoa
    savePerson: builder.mutation<SavePersonResponse, SavePersonRequest>({
      query: (data) => ({
        url: "/pessoa",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["People"],
    }),

    // Alterar Pessoa {{url_api}}/pessoa/8
    updatePerson: builder.mutation<
      SavePersonResponse,
      { pessoa_id: number; data: SavePersonRequest }
    >({
      query: ({ pessoa_id, data }) => ({
        url: `/pessoa/${pessoa_id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["People"],
    }),
  }),
});

export const {
  useGetPeopleQuery,
  useSavePersonMutation,
  useUpdatePersonMutation,
  useGetPersonByIdQuery,
} = peopleApi;
