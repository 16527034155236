// src/features/crud/components/CrudForm/index.tsx
import { useState, useEffect, useRef } from "react";
import { FieldRenderer } from "./components/FieldRenderer";
import ErrorAlert from "common/components/ErrorAlert";
import Button from "common/components/Button";
import {
  BaseEntity,
  CrudConfig,
  CrudMode,
  CrudField,
  LayoutGroup,
} from "features/crud/types";
import { ApiError, FormError } from "features/crud/types/error";
import {
  generateGridClasses,
  generateFieldSpanClasses,
} from "features/crud/utils/layout";
import { useCreateMutation, useUpdateMutation } from "features/crud/api";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export interface CrudFormProps<T extends BaseEntity> {
  config: CrudConfig<T>;
  onSuccess?: (mode: CrudMode, data: T) => void;
  onError?: (error: ApiError) => void;
  onCancel?: () => void;
  readOnly?: boolean;
  initialId?: number;
  mode?: CrudMode;
  initialData?: Partial<T>;
  initialFormError?: ApiError;
  validate?: (data: Partial<T>) => { isValid: boolean; error?: string };
  headerConfig?: {
    title: string;
    showBackButton?: boolean;
    backButtonLabel?: string;
    onBack?: () => void;
  };
}

const CrudForm = <T extends BaseEntity>({
  config,
  onSuccess,
  onError,
  onCancel,
  readOnly = false,
  initialId,
  mode = "create",
  initialData,
  initialFormError,
  validate,
  headerConfig,
}: CrudFormProps<T>) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<Partial<T>>({});
  const [formError, setFormError] = useState<FormError | null>(
    initialFormError
      ? {
          message: initialFormError.data.mensagem,
        }
      : null,
  );
  const [fieldErrors, setFieldErrors] = useState<Record<string, string>>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const errorRef = useRef<HTMLDivElement>(null);

  const entityType = config.entityName.toLowerCase();
  const [createItem] = useCreateMutation();
  const [updateItem] = useUpdateMutation();

  const apiConfig = config as unknown as CrudConfig<BaseEntity>;

  useEffect(() => {
    if (initialData) {
      setFormData(initialData);
    }
  }, [initialData]);

  useEffect(() => {
    if (formError && errorRef.current) {
      setTimeout(() => {
        errorRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 100);
    }
  }, [formError]);

  useEffect(() => {
    if (initialFormError) {
      setFormError({
        message: initialFormError.data.mensagem,
      });
    }
  }, [initialFormError]);

  const handleInputChange = (fieldName: keyof T, value: unknown) => {
    const field = config.fields.find((f) => f.name === fieldName);

    if (field?.transformValue?.output) {
      value = field.transformValue.output(value);
    }

    setFormData((prev) => ({
      ...prev,
      [fieldName]: value,
    }));

    if (fieldErrors[String(fieldName)]) {
      setFieldErrors((prev) => {
        const updated = { ...prev };
        delete updated[String(fieldName)];
        return updated;
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);

    if (validate) {
      const validationResult = validate(formData);
      if (!validationResult.isValid) {
        setFormError({
          message: validationResult.error || "Erro de validação",
        });
        setIsSubmitting(false);
        return;
      }
    }

    try {
      const response = initialId
        ? await updateItem({
            config: apiConfig,
            id: initialId,
            data: formData,
            entityType,
          }).unwrap()
        : await createItem({
            config: apiConfig,
            data: formData,
            entityType,
          }).unwrap();

      onSuccess?.(mode, response.data as T);
      setIsSubmitting(false);
    } catch (error) {
      const apiError = error as ApiError;
      const errorMessage =
        typeof apiError.data?.detalhes?.message === "string"
          ? apiError.data.detalhes.message
          : typeof apiError.data?.mensagem === "string"
            ? apiError.data.mensagem
            : "Ocorreu um erro ao processar a requisição";

      setFormError({
        message: errorMessage,
        fields: {
          ...(errorMessage.toLowerCase().includes("email") && {
            email: errorMessage,
          }),
        },
      });

      onError?.(apiError);
      setIsSubmitting(false);
    }
  };

  const handleBack = () => {
    if (headerConfig?.onBack) {
      headerConfig.onBack();
    } else {
      navigate(-1);
    }
  };

  const renderField = (field: CrudField<T>, groupLayout?: LayoutGroup) => {
    if (field.hidden) return null;
    if (field.condition && !field.condition(formData[field.name])) return null;

    const fieldLayout =
      groupLayout?.fieldLayouts?.[String(field.name)] ||
      config.layout?.fieldLayouts?.[String(field.name)];

    const spanClasses = generateFieldSpanClasses(fieldLayout);

    return (
      <div key={String(field.name)} className={`${spanClasses}`}>
        <FieldRenderer<T>
          field={field}
          value={formData[field.name]}
          onChange={handleInputChange}
          error={fieldErrors[String(field.name)]}
          disabled={readOnly || field.disabled}
          data={formData}
        />
      </div>
    );
  };

  const renderGroup = (group: LayoutGroup) => {
    const gridClasses = generateGridClasses(group.columns || { sm: 1 });

    return (
      <div key={group.fields.join("-")} className="space-y-2">
        {group.title && (
          <h3 className="text-lg font-medium mb-4">{group.title}</h3>
        )}
        <div className={`${gridClasses}`}>
          {group.fields.map((fieldName) => {
            const field = config.fields.find((f) => f.name === fieldName);
            if (!field) return null;
            return renderField(field, group);
          })}
        </div>
      </div>
    );
  };

  const renderFormContent = () => {
    if (!config.layout?.groups) {
      return config.fields.map((field) => (
        <div key={String(field.name)} className="mb-4">
          {renderField(field)}
        </div>
      ));
    }

    return (
      <div className="space-y-2">{config.layout.groups.map(renderGroup)}</div>
    );
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {headerConfig && (
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-[--primary] text-xl font-semibold">
            {headerConfig.title}
          </h1>
          {headerConfig.showBackButton && (
            <Button
              variant="link"
              onClick={handleBack}
              type="button"
              className="text-[--primary]"
            >
              {headerConfig.backButtonLabel ||
                `${mode === "create" || mode === "edit" ? t("button.cancel") : t("button.back")}`}
            </Button>
          )}
        </div>
      )}

      {formError && (
        <div ref={errorRef}>
          <ErrorAlert
            message={formError.message}
            onDismiss={() => setFormError(null)}
            className="mb-6"
          />
        </div>
      )}

      {renderFormContent()}

      <div className="flex justify-end gap-4">
        {onCancel && (
          <Button variant="link" onClick={onCancel} type="button">
            {mode === "view" ? t("button.back") : t("crudForm.cancel")}
          </Button>
        )}
        {!readOnly && (
          <Button type="submit" variant="primary" disabled={isSubmitting}>
            {isSubmitting ? t("crudForm.saving") : t("crudForm.save")}
          </Button>
        )}
      </div>
    </form>
  );
};

export default CrudForm;
