import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ErrorAlert from "common/components/ErrorAlert";
import Spinner from "common/components/Spinner";

import { useDescription } from "features/project/components/PDIProject/components/Project/components/Stepper/steps/Description/hooks/useDescription";
import ProjectKeywordsSection from "features/project/components/PDIProject/components/Project/components/Stepper/steps/Description/components/ProjectKeywordsSection";
import ProjectDescriptionSection from "features/project/components/PDIProject/components/Project/components/Stepper/steps/Description/components/ProjectDescriptionSection";
import ProjectMotivationSection from "features/project/components/PDIProject/components/Project/components/Stepper/steps/Description/components/ProjectMotivationSection";
import ProjectOriginalitySection from "features/project/components/PDIProject/components/Project/components/Stepper/steps/Description/components/ProjectOriginalitySection";
import ProjectApplicabilitySection from "features/project/components/PDIProject/components/Project/components/Stepper/steps/Description/components/ProjectApplicabilitySection";
import ProjectRelevanceSection from "features/project/components/PDIProject/components/Project/components/Stepper/steps/Description/components/ProjectRelevanceSection";
import ProjectCostReasonabilitySection from "features/project/components/PDIProject/components/Project/components/Stepper/steps/Description/components/ProjectCostReasonabilitySection";
import ProjectRelatedResearchSection from "features/project/components/PDIProject/components/Project/components/Stepper/steps/Description/components/ProjectRelatedResearchSection";
import StepContainer from "features/project/components/PDIProject/components/Project/components/Stepper/steps/Description/components/StepContainer";
import DescriptionView from "features/project/components/PDIProject/components/Project/components/Stepper/steps/Description/components/DescriptionView";

interface DescriptionProps {
  onSaveStep?: (success: boolean, projectId?: number) => void;
  viewMode?: "view" | "edit" | "create";
  projectId?: number;
  registerSaveHandler?: (handler: () => Promise<boolean>) => void;
}

const Description = ({
  onSaveStep,
  viewMode = "edit",
  projectId: initialProjectId,
  registerSaveHandler,
}: DescriptionProps) => {
  const { t } = useTranslation();
  const isViewMode = viewMode === "view";

  const {
    formData,
    inputValue,
    error,
    isLoadingDescription,
    isSaving,
    isAddingTag,
    handleTextareaChange,
    handleKeywordsChange,
    handleInputChange,
    handleKeyPress,
    handleRemoveTag,
    handleSave,
    setError,
  } = useDescription({
    initialProjectId,
    viewMode,
    onSaveStep,
  });

  useEffect(() => {
    if (registerSaveHandler) {
      registerSaveHandler(handleSave);
    }
  }, [registerSaveHandler, handleSave]);

  return (
    <StepContainer>
      <div className="flex flex-col gap-4 p-4 h-full">
        {error && (
          <ErrorAlert
            message={error}
            onDismiss={() => setError(null)}
            className="mb-2"
          />
        )}

        {isSaving && (
          <div className="flex justify-center items-center my-2">
            <Spinner size="sm" label={t("common.saving")} />
          </div>
        )}

        {isLoadingDescription && (
          <div className="flex justify-center items-center p-8">
            <Spinner
              size="lg"
              label={t("common.loading")}
              className="flex items-center justify-center"
            />
          </div>
        )}

        {!isLoadingDescription && isViewMode && (
          <DescriptionView formData={formData} />
        )}

        {(!isLoadingDescription || viewMode === "create") && !isViewMode && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 overflow-y-auto">
            <div className="col-span-1 md:col-span-2">
              <ProjectKeywordsSection
                formState={formData}
                isViewMode={isViewMode}
                handleKeywordsChange={handleKeywordsChange}
                onError={setError}
                inputValue={inputValue}
                handleInputChange={handleInputChange}
                handleKeyPress={handleKeyPress}
                handleRemoveTag={handleRemoveTag}
                isAddingTag={isAddingTag}
              />
            </div>

            <div className="col-span-1 md:col-span-2">
              <ProjectDescriptionSection
                formState={formData}
                isViewMode={isViewMode}
                handleTextareaChange={handleTextareaChange}
              />
            </div>

            <div className="col-span-1">
              <ProjectMotivationSection
                formState={formData}
                isViewMode={isViewMode}
                handleTextareaChange={handleTextareaChange}
              />
            </div>

            <div className="col-span-1">
              <ProjectOriginalitySection
                formState={formData}
                isViewMode={isViewMode}
                handleTextareaChange={handleTextareaChange}
              />
            </div>

            <div className="col-span-1">
              <ProjectApplicabilitySection
                formState={formData}
                isViewMode={isViewMode}
                handleTextareaChange={handleTextareaChange}
              />
            </div>

            <div className="col-span-1">
              <ProjectRelevanceSection
                formState={formData}
                isViewMode={isViewMode}
                handleTextareaChange={handleTextareaChange}
              />
            </div>

            <div className="col-span-1">
              <ProjectCostReasonabilitySection
                formState={formData}
                isViewMode={isViewMode}
                handleTextareaChange={handleTextareaChange}
              />
            </div>

            <div className="col-span-1">
              <ProjectRelatedResearchSection
                formState={formData}
                isViewMode={isViewMode}
                handleTextareaChange={handleTextareaChange}
              />
            </div>
          </div>
        )}
      </div>
    </StepContainer>
  );
};

export default Description;
