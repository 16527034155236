// src/features/crud/components/CrudForm/components/FieldRenderer/index.tsx
import {
  BaseEntity,
  CrudField,
  CrudComponentProps,
  InputFieldType,
} from "features/crud/types";
import Input from "common/components/Input";

interface FieldRendererProps<T extends BaseEntity> {
  field: CrudField<T>;
  value: T[keyof T] | undefined;
  onChange: (name: keyof T, value: T[keyof T]) => void;
  error?: string;
  disabled?: boolean;
  data?: Partial<T>;
}

export const FieldRenderer = <T extends BaseEntity>({
  field,
  value,
  onChange,
  error,
  disabled,
  data,
}: FieldRendererProps<T>) => {
  if (field.hidden) {
    return null;
  }

  if (field.condition && !field.condition(value)) {
    return null;
  }

  const handleComponentChange = (componentValue: unknown) => {
    const transformedValue = field.transformValue?.output
      ? field.transformValue.output(componentValue)
      : (componentValue as T[keyof T]);

    onChange(field.name, transformedValue);
  };

  const isFieldDisabled = disabled || field.disabled;

  const Content = () => {
    if (field.type === "component" && field.component) {
      const CustomComponent = field.component;

      const transformedValue = field.transformValue?.input
        ? field.transformValue.input(value as T[keyof T])
        : value;

      const componentProps: CrudComponentProps<T[keyof T]> = {
        value: transformedValue as T[keyof T] | null,
        onChange: handleComponentChange,
        error,
        label: field.label,
        required: field.required,
        disabled: isFieldDisabled,
        placeholder: field.placeholder,
        ...field.componentProps,
        data: data as Partial<T[keyof T]>,
      };

      return <CustomComponent {...componentProps} />;
    }

    const displayValue =
      field.type === "text" && field.transformValue?.display
        ? field.transformValue.display(value as T[keyof T], data)
        : value;

    return (
      <Input
        type={field.type as InputFieldType}
        label={field.label}
        value={displayValue !== undefined ? String(displayValue) : ""}
        onChange={(e) => {
          const newValue =
            field.type === "number" ? Number(e.target.value) : e.target.value;
          onChange(field.name, newValue as T[keyof T]);
        }}
        error={error}
        required={field.required}
        disabled={isFieldDisabled}
        placeholder={field.placeholder}
        {...field.componentProps}
      />
    );
  };

  return Content();
};

export default FieldRenderer;
