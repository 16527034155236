import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  useGetProjectRolesQuery,
  useGetProjectRolesListQuery,
  useRemoveUserFromProjectMutation,
  useInviteUserToProjectMutation,
  useAddUserToProjectMutation,
} from "features/project/api";
import { ProjectRolesData } from "features/project/types";

interface RoleUser {
  id: number;
  name: string;
  status?: "pending" | "active";
  projeto_usuario_papel_id?: number;
}

interface RoleColumn {
  id: number;
  title: string;
  users: RoleUser[];
  allowsInvite: boolean;
}

interface UseRolesProps {
  projectId?: number;
  viewMode?: "view" | "edit" | "create";
}

export const useRoles = ({ projectId, viewMode = "edit" }: UseRolesProps) => {
  const { t } = useTranslation();
  const [roleColumns, setRoleColumns] = useState<RoleColumn[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [removeUserFromProject] = useRemoveUserFromProjectMutation();
  const [inviteUserToProject] = useInviteUserToProjectMutation();
  const [addUserToProject] = useAddUserToProjectMutation();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [userToRemove, setUserToRemove] = useState<{
    id: number;
    name: string;
    projeto_usuario_papel_id?: number;
  } | null>(null);
  const [isRemoving, setIsRemoving] = useState(false);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState<{
    id: number;
    title: string;
  } | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inviteError, setInviteError] = useState<string | null>(null);
  const [addUserError, setAddUserError] = useState<string | null>(null);

  const {
    data: rolesData,
    isFetching: isLoadingRoles,
    error: rolesError,
  } = useGetProjectRolesQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const {
    data: rolesListData,
    isFetching: isLoadingRolesList,
    error: rolesListError,
  } = useGetProjectRolesListQuery(projectId || 0, {
    skip: !projectId || viewMode === "create",
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (rolesData?.data) {
      const columns = rolesData.data.map((role: ProjectRolesData) => {
        const roleUsers: RoleUser[] = [];

        if (projectId && rolesListData?.data?.projeto_papeis) {
          rolesListData.data.projeto_papeis.forEach((userRole) => {
            if (userRole.papel.papel_id === role.papel_id) {
              if (userRole.usuario) {
                roleUsers.push({
                  id: userRole.usuario.usuario_id,
                  name: userRole.usuario.nome,
                  status: "active",
                  projeto_usuario_papel_id: userRole.projeto_usuario_papel_id,
                });
              } else if (userRole.usuario_convite) {
                roleUsers.push({
                  id: userRole.usuario_convite.usuario_convite_id,
                  name: userRole.usuario_convite.nome_convidado,
                  status: userRole.usuario_convite.convite_aceito
                    ? "active"
                    : "pending",
                  projeto_usuario_papel_id: userRole.projeto_usuario_papel_id,
                });
              }
            }
          });
        }

        return {
          id: role.papel_id,
          title: role.descricao,
          users: roleUsers,
          allowsInvite: role.permite_convite,
        };
      });

      setRoleColumns(columns);
    }
  }, [
    rolesData,
    rolesListData,
    isLoadingRoles,
    isLoadingRolesList,
    rolesError,
    rolesListError,
    projectId,
    viewMode,
    t,
  ]);

  const showRemoveConfirmation = (user: {
    id: number;
    name: string;
    projeto_usuario_papel_id?: number;
  }) => {
    setUserToRemove(user);
    setIsConfirmModalOpen(true);
  };

  const handleRemoveUser = async () => {
    if (!projectId || !userToRemove?.projeto_usuario_papel_id) return;

    setIsRemoving(true);

    try {
      await removeUserFromProject({
        projectId,
        projeto_usuario_papel_id: userToRemove.projeto_usuario_papel_id,
      });

      setIsConfirmModalOpen(false);
      setIsSuccessModalOpen(true);
    } catch (err: any) {
      const errorMessage = err?.data?.mensagem || "Erro ao remover usuário";
      setError(errorMessage);
      setIsConfirmModalOpen(false);
    } finally {
      setIsRemoving(false);
    }
  };

  const closeSuccessModal = () => {
    setIsSuccessModalOpen(false);
    setUserToRemove(null);
  };

  const cancelRemoval = () => {
    setIsConfirmModalOpen(false);
    setUserToRemove(null);
  };

  const handleRoleHeaderClick = (
    roleId: number,
    roleTitle: string,
    allowsInvite: boolean,
  ) => {
    setSelectedRole({ id: roleId, title: roleTitle });
    if (allowsInvite) {
      setIsInviteModalOpen(true);
    } else {
      setIsAddUserModalOpen(true);
    }
  };

  const handleInviteUser = async (data: { name: string; email: string }) => {
    if (!projectId || !selectedRole) return;

    setIsSubmitting(true);
    try {
      await inviteUserToProject({
        projectId,
        data: {
          nome_convidado: data.name,
          email_convidado: data.email,
          papel_id: selectedRole.id,
        },
      }).unwrap();

      setIsInviteModalOpen(false);
    } catch (err: any) {
      const errorMessage = err?.data?.mensagem || "Erro ao convidar usuário";
      setInviteError(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAddUser = async (userId: number) => {
    if (!projectId || !selectedRole) return;

    setIsSubmitting(true);
    try {
      await addUserToProject({
        projectId,
        data: {
          usuario_id: userId,
          papel_id: selectedRole.id,
        },
      }).unwrap();

      setIsAddUserModalOpen(false);
    } catch (err: any) {
      const errorMessage = err?.data?.mensagem || "Erro ao adicionar usuário";
      setAddUserError(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    roleColumns,
    loading: isLoadingRoles || isLoadingRolesList,
    error,
    inviteError,
    setInviteError,
    addUserError,
    setAddUserError,
    setError,
    showRemoveConfirmation,
    handleRemoveUser,
    isConfirmModalOpen,
    isSuccessModalOpen,
    userToRemove,
    isRemoving,
    closeSuccessModal,
    cancelRemoval,
    handleRoleHeaderClick,
    isInviteModalOpen,
    setIsInviteModalOpen,
    isAddUserModalOpen,
    setIsAddUserModalOpen,
    selectedRole,
    handleInviteUser,
    handleAddUser,
    isSubmitting,
    projectName: rolesListData?.data?.nome || "",
  };
};
