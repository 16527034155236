import { configureStore } from "@reduxjs/toolkit";
import { authApi } from "features/auth/api";
import { companyApi } from "features/company/api";
import { profileSelectApi } from "features/profile/api";
import authReducer from "features/auth/slices/authSlice";
import selectedCompanyReducer from "features/user/slices/selectedCompanySlice";
import { crudApi } from "features/crud/api";
import { locationSelectApi } from "features/location/api";
import { serverTableApi } from "common/components/ServerTable/api";
import { userApi } from "features/user/api";
import { businessGroupApi } from "features/businessGroup/api";
import { inviteApi } from "features/invite/api";
import { bondApi } from "features/bond/api";
import { accountsPayableApi } from "features/accountsPayable/api";
import { entriesApi } from "features/entry/api";
import singleCompanyAccessReducer from "features/auth/slices/singleCompanyAccessSlice";
import { selicApi } from "features/selic/api";
import { forecastingApi } from "features/forecasting/api";
import breadcrumbReducer from "common/components/Breadcrumb/slices/breadcrumbSlice";
import { helpApi } from "features/help/api";
import { cucaApi } from "features/cuca/api";
import userReducer from "features/user/slices/userSlice";
import { projectApi } from "features/project/api";
import projectFormReducer from "features/project/slices/projectFormSlice";
import stepperReducer from "features/project/components/PDIProject/components/Project/components/Stepper/slices/stepperSlice";
import { dashboardApi } from "features/dashboard/api";
import { executingApi } from "features/executing/api";
import { peopleApi } from "features/people/api";

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [companyApi.reducerPath]: companyApi.reducer,
    [profileSelectApi.reducerPath]: profileSelectApi.reducer,
    [crudApi.reducerPath]: crudApi.reducer,
    [locationSelectApi.reducerPath]: locationSelectApi.reducer,
    [serverTableApi.reducerPath]: serverTableApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [inviteApi.reducerPath]: inviteApi.reducer,
    auth: authReducer,
    selectedCompany: selectedCompanyReducer,
    [businessGroupApi.reducerPath]: businessGroupApi.reducer,
    [bondApi.reducerPath]: bondApi.reducer,
    [accountsPayableApi.reducerPath]: accountsPayableApi.reducer,
    [entriesApi.reducerPath]: entriesApi.reducer,
    singleCompanyAccess: singleCompanyAccessReducer,
    [selicApi.reducerPath]: selicApi.reducer,
    [forecastingApi.reducerPath]: forecastingApi.reducer,
    breadcrumb: breadcrumbReducer,
    [helpApi.reducerPath]: helpApi.reducer,
    [cucaApi.reducerPath]: cucaApi.reducer,
    [projectApi.reducerPath]: projectApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [executingApi.reducerPath]: executingApi.reducer,
    [peopleApi.reducerPath]: peopleApi.reducer,
    user: userReducer,
    projectForm: projectFormReducer,
    stepper: stepperReducer,
  },
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: [
          "crudApi.queries",
          "locationSelectApi.queries",
          "companyApi.queries",
        ],
        ignoredActions: [
          "crudApi/executeQuery/fulfilled",
          "locationSelectApi/executeQuery/fulfilled",
          "companyApi/executeQuery/fulfilled",
        ],
      },
    });

    return middlewares.concat(
      authApi.middleware,
      companyApi.middleware,
      profileSelectApi.middleware,
      locationSelectApi.middleware,
      crudApi.middleware,
      serverTableApi.middleware,
      userApi.middleware,
      businessGroupApi.middleware,
      inviteApi.middleware,
      bondApi.middleware,
      accountsPayableApi.middleware,
      entriesApi.middleware,
      selicApi.middleware,
      forecastingApi.middleware,
      helpApi.middleware,
      cucaApi.middleware,
      projectApi.middleware,
      dashboardApi.middleware,
      executingApi.middleware,
      peopleApi.middleware,
    );
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
