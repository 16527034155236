// src/features/executing/api/index.ts

import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "config/baseQuery";
import {
  GetExecutingsResponse,
  GetExecutingsParams,
  GetExecutingByIdResponse,
  SaveExecutingResponse,
  SaveExecutingRequest,
} from "features/executing/types";

export const executingApi = createApi({
  reducerPath: "executingApi",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["Executing"],
  endpoints: (builder) => ({
    // Listar executoras {{url_api}}/executora
    getExecutings: builder.query<GetExecutingsResponse, GetExecutingsParams>({
      query: (params) => ({
        url: "/executora",
        params,
      }),
      providesTags: ["Executing"],
      keepUnusedDataFor: 0,
    }),

    // Get executora by ID {{url_api}}/executora/:id
    getExecutingById: builder.query<GetExecutingByIdResponse, number>({
      query: (id) => ({
        url: `/executora/${id}`,
      }),
      providesTags: (_result, _error, id) => [{ type: "Executing", id }],
    }),

    // Inserir Executora {{url_api}}/executora
    saveExecuting: builder.mutation<
      SaveExecutingResponse,
      SaveExecutingRequest
    >({
      query: (data) => ({
        url: "/executora",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Executing"],
    }),

    // Alterar Executora  {{url_api}}/executora/8
    updateExecuting: builder.mutation<
      SaveExecutingResponse,
      { executora_id: number; data: SaveExecutingRequest }
    >({
      query: ({ executora_id, data }) => ({
        url: `/executora/${executora_id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Executing"],
    }),
  }),
});

export const {
  useGetExecutingsQuery,
  useGetExecutingByIdQuery,
  useSaveExecutingMutation,
  useUpdateExecutingMutation,
} = executingApi;
