import Button from "common/components/Button";
import { useTranslation } from "react-i18next";
import { Plus, UserCircle2, Clock, CheckCircle2, Trash2 } from "lucide-react";

interface RoleUser {
  id: number;
  name: string;
  status?: "pending" | "active";
  projeto_usuario_papel_id?: number;
}

interface RoleColumnProps {
  title: string;
  users: RoleUser[];
  isViewMode?: boolean;
  allowsInvite?: boolean;
  onRemoveUser?: (user: RoleUser) => void;
  onHeaderClick?: (
    roleId: number,
    roleTitle: string,
    allowsInvite: boolean,
  ) => void;
  roleId: number;
}

const RoleColumn = ({
  title,
  users,
  isViewMode = false,
  allowsInvite = true,
  onRemoveUser,
  onHeaderClick,
  roleId,
}: RoleColumnProps) => {
  const { t } = useTranslation();

  const handleRemoveUser = (user: RoleUser) => {
    if (user.projeto_usuario_papel_id && onRemoveUser) {
      onRemoveUser(user);
    }
  };

  const handleHeaderClick = () => {
    if (onHeaderClick && !isViewMode) {
      onHeaderClick(roleId, title, allowsInvite);
    }
  };

  return (
    <div className="flex flex-col border border-primary-light rounded-md overflow-hidden shadow-sm">
      <Button
        className={`w-full rounded-t-md rounded-b-none text-center font-medium text-xs flex items-center justify-center gap-2 ${isViewMode ? "cursor-default hover:bg-primary" : ""}`}
        onClick={handleHeaderClick}
      >
        {title}
        {!isViewMode && <Plus className="w-4 h-4" />}
      </Button>
      <div className="flex flex-col p-0 bg-white">
        {users.length > 0 ? (
          <ul className="divide-y divide-primary-light">
            {users.map((user) => (
              <li
                key={`${user.projeto_usuario_papel_id || ""}-${user.id}`}
                className="group hover:bg-primary-light transition-colors duration-200"
              >
                <div className="p-3 flex items-center justify-between">
                  <div className="flex items-center gap-2 min-w-0">
                    <UserCircle2 className="w-5 h-5 flex-shrink-0 text-primary" />
                    <div className="flex flex-col min-w-0">
                      <span className="font-medium text-sm truncate">
                        {user.name}
                      </span>
                      {user.status === "pending" && (
                        <div className="flex items-center gap-1 text-xs text-text-secondary">
                          <Clock className="w-3 h-3" />
                          <span>Pendente</span>
                        </div>
                      )}
                      {user.status === "active" && (
                        <div className="flex items-center gap-1 text-xs text-success">
                          <CheckCircle2 className="w-3 h-3" />
                          <span>Ativo</span>
                        </div>
                      )}
                    </div>
                  </div>
                  {!isViewMode && (
                    <button
                      className="text-error p-1 rounded-full opacity-0 group-hover:opacity-100 transition-opacity hover:bg-error-bg"
                      onClick={() => handleRemoveUser(user)}
                    >
                      <Trash2 className="w-4 h-4" />
                    </button>
                  )}
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <div className="text-center text-sm py-6 px-3 text-text-secondary italic bg-light-bg">
            {t("project.rolesStep.roleGrid.noUsers")}
          </div>
        )}
      </div>
    </div>
  );
};

export default RoleColumn;
