import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ErrorAlert from "common/components/ErrorAlert";
import Spinner from "common/components/Spinner";

import { useIdentification } from "features/project/components/PDIProject/components/Project/components/Stepper/steps/Identification/hooks/useIdentification";
import BasicProjectInformation from "features/project/components/PDIProject/components/Project/components/Stepper/steps/Identification/components/BasicProjectInformation";
import ProjectCodesAndDates from "features/project/components/PDIProject/components/Project/components/Stepper/steps/Identification/components/ProjectCodesAndDates";
import FinancialInformation from "features/project/components/PDIProject/components/Project/components/Stepper/steps/Identification/components/FinancialInformation";
import ProjectClassification from "features/project/components/PDIProject/components/Project/components/Stepper/steps/Identification/components/ProjectClassification";
import ThemeInformation from "features/project/components/PDIProject/components/Project/components/Stepper/steps/Identification/components/ThemeInformation";
import StepContainer from "features/project/components/PDIProject/components/Project/components/Stepper/steps/Identification/components/StepContainer";
import IdentificationView from "features/project/components/PDIProject/components/Project/components/Stepper/steps/Identification/components/IdentificationView";

interface IdentificationProps {
  onSaveStep?: (success: boolean, projectId?: number) => void;
  projectId?: number;
  viewMode?: "view" | "edit" | "create";
  registerSaveHandler?: (handler: () => Promise<boolean>) => void;
}

const Identification = ({
  onSaveStep,
  viewMode = "edit",
  projectId: initialProjectId,
  registerSaveHandler,
}: IdentificationProps) => {
  const { t } = useTranslation();
  const isViewMode = viewMode === "view";

  const {
    formData,
    handleInputChange,
    handleCurrencyChange,
    handleNumericInput,
    productTypeOptions,
    innovationPhaseOptions,
    sharingTypeOptions,
    trlOptions,
    isLoadingProductType,
    isLoadingInnovationPhase,
    isLoadingSharingType,
    isLoadingTrl,
    isLoadingIdentification,
    isSaving,
    error,
    setError,
    handleSave,
  } = useIdentification({
    initialProjectId,
    viewMode,
    onSaveStep,
  });

  useEffect(() => {
    if (registerSaveHandler) {
      registerSaveHandler(handleSave);
    }
  }, [registerSaveHandler, handleSave]);

  return (
    <StepContainer>
      {error && (
        <ErrorAlert
          message={error}
          onDismiss={() => setError(null)}
          className="mb-2"
        />
      )}

      {isSaving && (
        <div className="flex justify-center items-center my-2">
          <Spinner size="sm" label={t("common.saving")} />
        </div>
      )}

      {isLoadingIdentification && (
        <div className="flex justify-center items-center p-8">
          <Spinner
            size="lg"
            label={t("common.loading")}
            className="flex items-center justify-center"
          />
        </div>
      )}

      {!isLoadingIdentification && isViewMode && (
        <IdentificationView
          formData={formData}
          productTypeOptions={productTypeOptions}
          innovationPhaseOptions={innovationPhaseOptions}
          sharingTypeOptions={sharingTypeOptions}
        />
      )}

      {!isLoadingIdentification && !isViewMode && (
        <div className="flex flex-col gap-4">
          <BasicProjectInformation
            formData={formData}
            handleInputChange={handleInputChange}
            isViewMode={isViewMode}
            displayProjectType={formData.projectTypeDisplay}
          />

          <ProjectCodesAndDates
            formData={formData}
            handleInputChange={handleInputChange}
            handleNumericInput={handleNumericInput}
            isViewMode={isViewMode}
            trlOptions={trlOptions}
            isLoadingTrl={isLoadingTrl}
          />

          <FinancialInformation
            formData={formData}
            handleInputChange={handleInputChange}
            handleCurrencyChange={handleCurrencyChange}
            isViewMode={isViewMode}
          />

          <ProjectClassification
            formData={formData}
            handleInputChange={handleInputChange}
            isViewMode={isViewMode}
            productTypeOptions={productTypeOptions}
            innovationPhaseOptions={innovationPhaseOptions}
            sharingTypeOptions={sharingTypeOptions}
            isLoadingProductType={isLoadingProductType}
            isLoadingInnovationPhase={isLoadingInnovationPhase}
            isLoadingSharingType={isLoadingSharingType}
          />

          <ThemeInformation
            formData={formData}
            handleInputChange={handleInputChange}
            isViewMode={isViewMode}
          />
        </div>
      )}
    </StepContainer>
  );
};

export default Identification;
