import Modal from "common/components/Modal";
import Button from "common/components/Button";

interface ConfirmModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title?: string;
  message: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  size?: "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl" | "5xl" | "fit";
  isLoading?: boolean;
}

const ConfirmModal = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  message,
  confirmButtonText,
  cancelButtonText,
  size = "sm",
  isLoading = false,
}: ConfirmModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      size={size}
      closeOnBackdropClick={false}
      footer={
        <div className="flex items-center justify-center gap-4">
          {cancelButtonText && (
            <Button variant="link" onClick={onClose} disabled={isLoading}>
              {cancelButtonText}
            </Button>
          )}
          <Button onClick={onConfirm} disabled={isLoading}>
            {isLoading ? "Carregando" : confirmButtonText}
          </Button>
        </div>
      }
    >
      <div className="flex flex-col items-center justify-center text-center">
        <div className="mb-4">
          <svg
            className="w-16 h-16 text-yellow-500 mx-auto"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
        </div>

        <p className="text-lg mb-4">{message}</p>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
